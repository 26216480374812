import React from 'react';
import { graphql} from 'gatsby';
import Homepage from '../templates/multilanguage/Homepage.js';

export default ({ data }) => {
    return <Homepage data={data} />;
};

export const query = graphql`
 query Homepage {
  mdx(frontmatter: {type: {eq: "homepage"}}) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        type
        title
        headerTitle
        subHeaderTitle
        subTitle
        subTitle2
        subTitle3
        metaTitle
        metaDescription
        metaImage {
          publicURL
        }
        metaCover {
          publicURL
        }
        heroImage {
          publicURL
        }
        heroCloud {
          publicURL
        }
        metaKeywords
        twitterCard
        ldJson
      }
  }
}
`
