import React from 'react';
import SiteLayout from '../../components/layout/SiteLayout';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const Homepage = ({ data }) => {
  return (
    <SiteLayout relatedPages={data?.mdx?.fields?.relatedPages} canonical={data?.mdx?.fields?.canonical} title={data?.mdx?.frontmatter?.title} seo={{metaTitle: data?.mdx?.frontmatter?.metaTitle, metaDescription: data?.mdx?.frontmatter?.metaDescription, metaImage: data?.mdx?.frontmatter?.metaImage?.publicURL, metaCover: data?.mdx?.frontmatter?.metaCover?.publicURL, twitterCard: data?.mdx?.frontmatter?.twitterCard, ldJson: data?.mdx?.frontmatter?.ldJson, keywords: data?.mdx?.frontmatter?.metaKeywords, slug: data?.mdx?.fields?.slug.replace("/posts", "")}}>
      <div className={"w-full flex flex-col px-5 md:px-10 lg:px-40 xl:px-80 xxl:px-96 hp-body "}>
          <div className={"relative w-full flex flex-col lg:flex-row items-center justify-center justify-items-center"}>
              <div className={"relative w-full lg:w-1/2 xl:w-1/2 xxl:w-1/3 flex flex-col z-50"}>
                  <h1 className={"text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-4xl text-primary-default font-semibold text-center lg:text-left mb-4"}>{data?.mdx?.frontmatter?.headerTitle}</h1>
                  <p className={"text-primary-default font-light text-center lg:text-left text-xs 2xl:text-sm py-2"}>{data?.mdx?.frontmatter?.subHeaderTitle}</p>
                  <li className={"text-primary-default font-light text-center lg:text-left text-xs 2xl:text-sm pt-2"}>{data?.mdx?.frontmatter?.subTitle}</li>
                  <li className={"text-primary-default font-light text-center lg:text-left text-xs 2xl:text-sm"}>{data?.mdx?.frontmatter?.subTitle2}</li>
                  <li className={"text-primary-default font-light text-center lg:text-left text-xs 2xl:text-sm"}>{data?.mdx?.frontmatter?.subTitle3}</li>
              </div>
              <div className={"w-full xl:-ml-2 2xl:-ml-28 xxl:-ml-32"}>
                  <img src={data?.mdx?.frontmatter?.heroImage?.publicURL} className={"z-10 w-full"} />
              </div>
          </div>
          <h2 className={"text-xl md:text-2xl lg:text-2xl text-center text-primary-default font-light pt-20 pb-5"}>{data?.mdx?.frontmatter?.title}</h2>
      </div>
      <div className={"w-full flex flex-col px-5 md:px-10 lg:px-40 xl:px-80 xxl:px-96   py-10 text-gray-500 text-sm mdx-pp-tos mb-14"}>
        <MDXRenderer>{data?.mdx?.body}</MDXRenderer>
      </div>
    </SiteLayout>
  );
};

export default Homepage;
